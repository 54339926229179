import request from "../utils/request";
import Vue from "vue";
import "../env";
import {
  getCookie,
  getLocalStorage,
  setLocalStorage,
  setSessionStorage,
} from "@/utils/stage";
import store from "@/store";

let access_token = "";
if (getCookie("access_token")) {
  access_token = getCookie("access_token");
} else {
  access_token = getLocalStorage("accountStorage")
    ? JSON.parse(getLocalStorage("accountStorage")).access_token
    : "";
}
const obj = {
  lang: Vue.prototype.$isEn ? "en-us" : Vue.prototype.$isTw ? "zh-tw" : "ko-kr",
  app_id: Vue.prototype.$appId,
  access_token: access_token,
  open_id: getLocalStorage("accountStorage")
    ? JSON.parse(getLocalStorage("accountStorage")).open_id
    : "",
};

export function gameConfig() {
  let configData = {};
  // if (window.location.host.indexOf("fungoglobal") > -1) {
  //   // 正式服
  //   configData = {
  //     platform: "pc",
  //     app_id: obj.app_id,
  //     kid: "666666666",
  //     lang: obj.lang,
  //   };
  // } else {
  //   // 测试服
  //   configData = {
  //     platform: "pc",
  //     app_id: obj.app_id,
  //     kid: "6666",
  //     lang: obj.lang,
  //   };
  // }
  configData = {
    platform: "pc",
    app_id: obj.app_id,
    kid: "6666",
    lang: obj.lang,
    sdk_code: 3,
  };
  setSessionStorage("message", JSON.stringify(configData));
  setLocalStorage("message", JSON.stringify(configData));
  store.dispatch("info/getGameInfo").then((data) => {
    // if (getToken("login") == "success") {
    //   router.push("/safetySettings");
    // } else {
    //   router.push("/");
    //   removeLocalStorage("accountStorage");
    // }
  });
}

// 游戏参数-short
export function getAppShort(data) {
  return request({
    url: "/app/short",
    method: "post",
    dataType: "json",
    data: Object.assign({}, obj, data),
  });
}

// 读取玩家游戏（不分页）
export function getGameAll(data) {
  return request({
    url: "/user/get_game_all",
    method: "post",
    dataType: "json",
    data: Object.assign({}, obj, data),
  });
}

// 海外商品列表
export function webItemList(data) {
  return request({
    url: "/user/web_item_list",
    method: "post",
    dataType: "json",
    data: Object.assign({}, obj, data),
  });
}

// 付費方式和品項代碼查詢
export function myCardItemList(data) {
  return request({
    url: "/pay/my_card_item_list",
    method: "post",
    dataType: "json",
    data: Object.assign({}, obj, data),
  });
}

// myCard网页支付
export function myCardWebOrder(data) {
  return request({
    url: "/pay/my_card_common_order",
    method: "post",
    dataType: "json",
    data: Object.assign({}, obj, data),
  });
}

// myCard卡密支付
export function myCardInGamePay(data) {
  return request({
    url: "/pay/my_card_point_order",
    method: "post",
    dataType: "json",
    data: Object.assign({}, obj, data),
  });
}

// gash网页支付
export function gashCommonOrder(data) {
  return request({
    url: "/pay/gash_common_order",
    method: "post",
    dataType: "json",
    data: Object.assign({}, obj, data),
  });
}

// gash卡密支付
export function gashPointOrder(data) {
  return request({
    url: "/pay/gash_point_order",
    method: "post",
    dataType: "json",
    data: Object.assign({}, obj, data),
  });
}

// PayPal支付
export function payPalWebOrder(data) {
  return request({
    url: "/pay/pay_pal_web_order",
    method: "post",
    dataType: "json",
    data: Object.assign({}, obj, data),
  });
}

// pay letter
export function payLetterOrder(data) {
  return request({
    url: "/pay/pay_letter_web_order",
    method: "post",
    dataType: "json",
    data: Object.assign({}, obj, data),
  });
}

// 支付状态查询
export function payQuery(data) {
  return request({
    url: "/pay/query",
    method: "post",
    dataType: "json",
    data: Object.assign({}, obj, data),
  });
}

// paypal网页自定义支付
export function payPalCustom(data) {
  return request({
    url: "/pay/pay_pal_custom_web_order",
    method: "post",
    dataType: "json",
    data: Object.assign({}, obj, data),
  });
}

// payLetter网页自定义支付
export function payLetterCustom(data) {
  return request({
    url: "/pay/pay_letter_custom_web_order",
    method: "post",
    dataType: "json",
    data: Object.assign({}, obj, data),
  });
}

// 优惠券列表
export function myCoupon(data) {
  return request({
    url: "/coupon/my",
    method: "post",
    dataType: "json",
    data: Object.assign({}, obj, data),
  });
}

// 关闭订单
export function payClose(data) {
  return request({
    url: "/pay/close",
    method: "post",
    dataType: "json",
    data: Object.assign({}, obj, data),
  });
}

// 查询冻结中的优惠券
export function queryCouponOrder(data) {
  return request({
    url: "/pay/query_coupon_order",
    method: "post",
    dataType: "json",
    data: Object.assign({}, obj, data),
  });
}

// 验证access token
export function verifyToken(data) {
  return request({
    url: "/user/verify_token",
    method: "post",
    dataType: "json",
    data: Object.assign({}, obj, data),
  });
}

// 我的订单
export function myTrade(data) {
  return request({
    url: "/trade/my",
    method: "post",
    dataType: "json",
    data: Object.assign({}, obj, data),
  });
}

// 优惠券兑换
export function exchangeCode(data) {
  return request({
    url: "/coupon/exchange_code",
    method: "post",
    dataType: "json",
    data: Object.assign({}, obj, data),
  });
}
