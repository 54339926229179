import request from "../utils/request";
// 初始参数 /app/config
export function getconfig(data) {
  return request({
    url: "/app/config",
    method: "post",
    data,
  });
}

// /app/agreement 用户协议
export function agreement(data) {
  return request({
    url: "/app/agreement",
    method: "post",
    data,
  });
}
// /app/query
export function appQuery(data) {
  return request({
    url: "/app/query",
    method: "post",
    data,
  });
}