import Vue from "vue";
import { getLocalStorage } from "@/utils/stage";
Vue.prototype.$isEn =
  getLocalStorage("lang") === "en" ||
  window.location.href.indexOf("en-us") > -1 ||
  window.location.href.indexOf("payment.catfantasygame.com") > -1;
Vue.prototype.$isTw =
  getLocalStorage("lang") === "tw" ||
  window.location.href.indexOf("tw") > -1 ||
  window.location.href.indexOf("paymenttw.catfantasygame.com") > -1;
Vue.prototype.$isKr =
  getLocalStorage("lang") === "kr" ||
  window.location.href.indexOf("kr") > -1 ||
  window.location.href.indexOf("paymentkr.catfantasygame.com") > -1;

if(window.location.href.indexOf("passport-web-pay.17atv.com") > -1 || process.env.NODE_ENV === "development") {
  // if(!Vue.prototype.$isKr && !Vue.prototype.$isEn) Vue.prototype.$isTw = true // 默认台湾
  if(!Vue.prototype.$isEn && !Vue.prototype.$isTw) Vue.prototype.$isKr = true
  // if(!Vue.prototype.$isKr && !Vue.prototype.$isTw) Vue.prototype.$isEn = true
}

// 正式服 项目ID tw46  新马47  kr50
// 测试服 项目ID tw45  新马47  kr51
Vue.prototype.$projectList = {
  tw: {
    name: "貓之城",
    projectId: 46,
    app_id: "949792f00e554719ef2ee6f6deef0cc5",
    client_game_app_id: "2972947d72960283a623e9cea1aae4da",
    test_projectId: 45,
    test_app_id: "3b0c348056a155bdbf415939a2c2de2c",
    test_client_game_app_id: "b2b2d5a215af171cc8935b1d237e0635",
  },
  kr: {
    name: "캣 판타지",
    projectId: 50,
    app_id: "87c98d10d15a9a460110578ef1061885",
    client_game_app_id: "ceb782c283b0b70c43544a70783d641d",
    test_projectId: 51,
    test_app_id: "d82b2af6056f68976d0bd9c9cf6aea2f",
    test_client_game_app_id: "81d4b4bef73ee1214568d92ccaa6de91",
  },
  en: {
    name: "catfantasy",
    projectId: 45,
    app_id: "27baeca333b1131c32da979cd86dab2c",
    client_game_app_id: "ecc60841370368a7555a9c2edda45f8f",
    test_projectId: 47,
    test_app_id: "aeb578a446b244718c8786ee02905a57",
    test_client_game_app_id: "ae5b63c7f24723428c7ac18f509414d6",
  },
};
let projectId = "";
let client_game_app_id = "";
let app_id = "";
let gameName = "";
if (process.env.NODE_ENV === "production") {
  if (window.location.href.indexOf("passport-web-pay.17atv.com") > -1) {
    if (Vue.prototype.$isEn) {
      app_id = Vue.prototype.$projectList.en.test_app_id;
      projectId = Vue.prototype.$projectList.en.test_projectId;
      client_game_app_id = Vue.prototype.$projectList.en.test_client_game_app_id;
      gameName = Vue.prototype.$projectList.en.name;
    } else if (Vue.prototype.$isTw) {
      app_id = Vue.prototype.$projectList.tw.test_app_id;
      projectId = Vue.prototype.$projectList.tw.test_projectId;
      client_game_app_id = Vue.prototype.$projectList.tw.test_client_game_app_id;
      gameName = Vue.prototype.$projectList.tw.name;
    } else if (Vue.prototype.$isKr) {
      app_id = Vue.prototype.$projectList.kr.test_app_id;
      projectId = Vue.prototype.$projectList.kr.test_projectId;
      client_game_app_id = Vue.prototype.$projectList.kr.test_client_game_app_id;
      gameName = Vue.prototype.$projectList.kr.name;
    } else {
      app_id = Vue.prototype.$projectList.tw.test_app_id;
      projectId = Vue.prototype.$projectList.tw.test_projectId;
      client_game_app_id = Vue.prototype.$projectList.tw.test_client_game_app_id;
      gameName = Vue.prototype.$projectList.tw.name;
    }
  } else {
    if (Vue.prototype.$isEn) {
      app_id = Vue.prototype.$projectList.en.app_id;
      projectId = Vue.prototype.$projectList.en.projectId;
      client_game_app_id = Vue.prototype.$projectList.en.client_game_app_id;
      gameName = Vue.prototype.$projectList.en.name;
    } else if (Vue.prototype.$isTw) {
      app_id = Vue.prototype.$projectList.tw.app_id;
      projectId = Vue.prototype.$projectList.tw.projectId;
      client_game_app_id = Vue.prototype.$projectList.tw.client_game_app_id;
      gameName = Vue.prototype.$projectList.tw.name;
    } else {
      app_id = Vue.prototype.$projectList.kr.app_id; // 默认isKr
      projectId = Vue.prototype.$projectList.kr.projectId;
      client_game_app_id = Vue.prototype.$projectList.kr.client_game_app_id;
      gameName = Vue.prototype.$projectList.kr.name;
    }
  }
} else {
  if (Vue.prototype.$isEn) {
    app_id = Vue.prototype.$projectList.en.test_app_id;
    projectId = Vue.prototype.$projectList.en.test_projectId;
    client_game_app_id = Vue.prototype.$projectList.en.test_client_game_app_id;
    gameName = Vue.prototype.$projectList.en.name;
  } else if (Vue.prototype.$isTw) {
    app_id = Vue.prototype.$projectList.tw.test_app_id;
    projectId = Vue.prototype.$projectList.tw.test_projectId;
    client_game_app_id = Vue.prototype.$projectList.tw.test_client_game_app_id;
    gameName = Vue.prototype.$projectList.tw.name;
  } else if (Vue.prototype.$isKr) {
    app_id = Vue.prototype.$projectList.kr.test_app_id;
    projectId = Vue.prototype.$projectList.kr.test_projectId;
    client_game_app_id = Vue.prototype.$projectList.kr.test_client_game_app_id;
    gameName = Vue.prototype.$projectList.kr.name;
  } else {
    app_id = Vue.prototype.$projectList.tw.test_app_id;
    projectId = Vue.prototype.$projectList.tw.test_projectId;
    client_game_app_id = Vue.prototype.$projectList.tw.test_client_game_app_id;
    gameName = Vue.prototype.$projectList.tw.name;
  }
}
Vue.prototype.$appId = app_id;
Vue.prototype.$projectId = projectId;
Vue.prototype.$client_game_app_id = client_game_app_id;
Vue.prototype.$gameName = gameName;
