<template>
  <div class="trade_Page">
    <router-view :key="key()" />
  </div>
</template>

<script>
import configData from "@/utils/configData";
export default {
  data() {
    return {
      select: 1,
    };
  },
  created() {
    document.title = "支付详情";
  },
  methods: {
    key() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss" scoped>
.payPage {
  width: 100%;
  height: 100vh;
  background: #fff;
  position: relative;
}
</style>
