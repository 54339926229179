<template>
  <div class="main">
    <router-view :key="key()" />
  </div>
</template>

<script>
export default {
  name: "index",
  methods: {
    key() {
      return this.$route.path;
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  max-width: 800px;
  padding: 0 20px;
  margin: 50px auto;
  text-align: center;
  position: relative;
  font-size: 14px;
}
</style>
