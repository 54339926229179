<template>
  <div class="game_home">
    <div class="user_login_box"
         v-show="!controflag">
      <!-- <div class="close passIconfont  icon-ftshanchu"
           @click="close"></div> -->
      <div class="ft_logo"></div>
      <div class="cat_login_box">
        <router-view @flagChange="flagChange" />
      </div>
    </div>
    <agreement v-show="controflag"
               @flagChange="flagChange" />
  </div>
</template>

<script>
import { closeGame } from '@/utils/gameapi'
import agreement from './components/agreement.vue'
export default {
  components: {
    agreement,
  },
  data() {
    return {
      backFlag: false,
      controflag: false,
    }
  },
  watch: {
    $route: function (to, from) {
      if (
        this.$route.path.indexOf('/accountlogin') > -1 ||
        this.$route.path.indexOf('/checkuser') > -1
      ) {
        this.backFlag = true
      } else {
        this.backFlag = false
      }
    },
  },
  created() {},
  methods: {
    flagChange(data) {
      this.controflag = data
    },
    point() {},
    play() {
      this.$refs.audio.play()
    },
    key() {
      return this.$route.path
    },
    back() {
      this.$router.go(-1) //返回上一层
    },
    play() {},
    close() {
      closeGame()
    },
    closeBackBtn() {
      this.backFlag = false
    },
  },
}
</script>


<style scoped lang="scss">
input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #dcdcdc !important;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #dcdcdc !important;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #dcdcdc !important;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #dcdcdc !important;
}
@media (min-width: 1600px) {
  .game_home {
    width: 100vw;
    height: 100vh;
    background: url('../../assets/images/bg@1x.png') no-repeat;

    // background: rgba(255, 255, 255, 0);
    background-size: cover;
    position: relative;
    .user_login_box {
      width: 700px;
      height: 590px;
      background: rgba(255, 255, 255, 1);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      // padding-top: (195vw/19.2);
      box-sizing: border-box;
      .ft_logo {
        width: 272px;
        height: 113px;
        background: url('../../assets/images/ft_logo@1x.png') no-repeat;
        background-size: contain;
        padding-top: 8px;
        margin: 0 auto;
      }
      .close {
        width: 25px;
        height: 25px;
        font-size: 25px;
        position: absolute;
        top: 30px;
        right: 39px;
        cursor: pointer;
        color: #707070;
      }
      .goback {
        width: 25px;
        height: 25px;
        font-size: 25px;
        position: absolute;
        top: 30px;
        left: 39px;
        cursor: pointer;
        color: #707070;
      }
    }
  }
}
@media (min-width: 1200px) and (max-width:1599px) {
  .game_home {
    width: 100vw;
    height: 100vh;
    min-width: 1000 * 0.85px;
    background: url('../../assets/images/bg@1x.png') no-repeat;

    background-size: cover;
    position: relative;

    .user_login_box {
      width: 700 * 0.85px;
      height: 590 * 0.85px;
      background: rgba(255, 255, 255, 1);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      // padding-top: (195vw/19.2);
      box-sizing: border-box;

      .ft_logo {
        width: 272 * 0.85px;
        height: 113 * 0.85px;
        background: url('../../assets/images/ft_logo@1x.png') no-repeat;
        background-size: contain;
        padding-top: 8 * 0.85px;
        margin: 0 auto;
      }
      .close {
        width: 25 * 0.85px;
        height: 25 * 0.85px;
        font-size: 25 * 0.85px;
        position: absolute;
        top: 30 * 0.85px;
        right: 39 * 0.85px;
        cursor: pointer;
        color: #707070;
      }
      .goback {
        width: 25 * 0.85px;
        height: 25 * 0.85px;
        font-size: 25 * 0.85px;
        position: absolute;
        top: 30 * 0.85px;
        left: 39 * 0.85px;
        cursor: pointer;
        color: #707070;
      }
    }
  }
}
@media screen and (max-width:1199px) {
  .game_home {
    width: 100vw;
    height: 100vh;
    min-width: 1000 * 0.7px;
    background: url('../../assets/images/bg@1x.png') no-repeat;

    // background: rgba(255, 255, 255, 0);
    background-size: cover;
    position: relative;

    .user_login_box {
      width: 700 * 0.7px;
      height: 590 * 0.7px;
      background: rgba(255, 255, 255, 1);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      // padding-top: (195vw/19.2);
      box-sizing: border-box;

      .ft_logo {
        width: 272 * 0.7px;
        height: 113 * 0.7px;
        background: url('../../assets/images/ft_logo@1x.png') no-repeat;
        background-size: contain;
        padding-top: 8 * 0.7px;
        margin: 0 auto;
      }
      .close {
        width: 25 * 0.7px;
        height: 25 * 0.7px;
        font-size: 25 * 0.7px;
        position: absolute;
        top: 30 * 0.7px;
        right: 39 * 0.7px;
        cursor: pointer;
        color: #707070;
      }
      .goback {
        width: 25 * 0.7px;
        height: 25 * 0.7px;
        font-size: 25 * 0.7px;
        position: absolute;
        top: 30 * 0.7px;
        left: 39 * 0.7px;
        cursor: pointer;
        color: #707070;
      }
    }
  }
}
</style>
