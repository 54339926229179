// 根据电脑来判断语言
module.exports = {
  toast: {
    agreementTips: "Please read and agree to Terms and Conditions first.",
    nopsw: "Please enter your password",
    verifypAccount: "Invalid account format",
    noAccount: "Please enter your account",
    gotoGame: "Log in",
    noPhone: "Please enter your email",
    verifyphoneTips: "Please enter a valid email.",
    verifycodeTips: "Verification code cannot be empty.",
    verifypswTips: "Password must be 6-18 characters long and should not contain spaces and Chinese.",
    newverifypswTips: "Please enter a new password with 6-18 characters long, no spaces and Chinese.",

    noName: "Please enter your name.",
    noID: "",
    verifyIDTips: "",
    confirmPswTips: "Make sure to enter the same passwords twice.",

    accountPlaceholder: "Please enter your email",
    phonePlaceholder: "Please enter your email",
    pswPlaceholder: "Please enter your password",
    newPswPlaceHolder: "Please enter a new password",
    confirmPswPlaceholder: "Please confirm your password",
    IDPlaceholder: "",
    namePlaceholder: "",
    verifycodePlaceholder: "Please enter the verification code",
    findAccountPlaceHolder: "Please enter the account you want to retrieve",

    findAccountTxt1: "Please enter the account for which you need to reset the password.",
    findAccountTxt2: "Tap to receive the verification code. The text message will be sent to ",
    verifycodeBtn: "Send",
    verifycodeBtn2: "Send Again",
    gotoRegister: "Register now",
    findAccount: "Forgot",
    gotoAccountLogin: "Account Login",
    agreementText: "I've read and agree to",
    userAgreementName: "Terms of Service",
    privacyAgreementName: "Privacy Policy",
    agreementText2: "Completing registration means you agree to",
    loginErrorTips: "Login status error. Security verification required.",
    phoneVerify: "Email Verification",
    and: "and",
    comfirmBtn1: "Submit",
    comfirmBtn2: "Confirm",
    comfirmBtn3: "Register",
    comfirmBtn4: "Back",
    comfirmBtn5: "Cancel",
    comunicaton1: "Email",
    comunicaton2: "Email",
    changepswTxt1: "Set Account",
    changepswTxt2: "New password of ",

    bannerGameTxt1: "Notice",
    bannerGameTxt2: "",
    bannerGameTxt3: "",

    agreementTips1: "Terms of Service has been updated. Please read through it carefully and tap Agree to use this product.",
    agreementTips2: "Read and agree to all agreements to continue",
    agreementTips3: "Refuse",
    agreementTips4: "Agree",
    agreementTips5: "Read all contents and Tap Agree.",
    agreementTips6: "Please read and agree to Agreement first, then log in the game.",

    bindIdTxt1: "",
    bindIdTxt2: "",

    bindPhoneTxt1: "Please enter the email you want to connect:",

    loginErrorTxt1: "Login status error. Security verification required.",
    loginErrorTxt2: "Email Verification:",

    successTitle: "Welcome to the game",
  },
  myCard: {
    titleAccount: "Account Info",
    account: "Account",
    activeAccount: "Current Account",
    password: "Password",
    titlePayWay: "Top-up Channel",
    titlePayAccount: "Top-up Amount",
    titlePayList: "Opal List",
    myCardPoint: "MyCard Points",
    myCardGiftNum: "Opal(s)",
    submit: "Submit",
    change: "Top-up Exchange",
    logout: "Log out",
    gameRole: "Character",
    noGameRole: "No connected character. Please change account and try again.",
    point: 'Point',
    cardNum: 'Card No.',
    cardPass: 'Code',
    placeholderCardNum: 'Please enter Card No.',
    placeholderCardPass: 'Please enter Code',
    extraGift: 'Extra gift',
    time: 'Countdown',
    timeout: 'Closed',
    payWay1: 'By Gash',
    payWay2: 'Point Card',
    payWay3: 'By MyCard',
    payWay4: 'Card Top-up',
    secTitle1: 'Limited',
    secTitle2: 'Value',
    secTitle3: 'Exclusive',
    secTitle4: 'Permanent',
    failed: 'Operation Failed',
    tip: 'Double bonus upon your first top-up (one time only) in all platforms.',
    days: 'Days',
    day: 'Day',
    pay1: 'Please complete the payment first.',
    pay2: 'Complete',
    pay3: 'No result found.',
    pay4: 'If you\'ve complete the payment, please check in the game later.',
    pay5: 'Please check in the game later.',
    loginFirst: 'Please Log In to Your Game Account',
    extraSend: 'Extra Gift:',
    extraTitle: 'Customize Gem Amount',
    stock: 'Stock:',
    otherNum: 'Other Amounts',
    chooseCoupon: 'Choose Coupon',
    loginTips: 'Viewable After Logging In',
    noCoupon: 'No Coupons Available',
    expireAt: 'Expires On:',
    disCount: 'off',
    useType: 'Available with a minimum purchase of {amount}', // 满 KRW1000 可用
    proInfo: 'Product Information:',
    proNum: 'Product Quantity:',
    roleInfo: 'Character Information:',
    proPrice: 'Original Product Price:',
    disCountPrice: 'Discount Amount:',
    totalPrice: 'Total:',
    inputNum: 'Enter Recharge Amount',
    extraTips: 'Note: The recharge amount must be greater than 6480 and a multiple of 10, with a maximum of 99990 per transaction',
    chooseRole: 'Character',
    maxNum: 'Maximum Discount',
    couponTips: 'You have an order in progress that used a coupon. Would you like to cancel the order and refund the coupon?',
    couponTips2: '',
    useCoupon: 'Coupon Used:',
    originalAmount: 'Original Price:',
    productSubject: 'Product Details:',
    payAmount: 'Payment Amount:',
    couponBtn1: 'Cancel and Refund Coupon',
    couponBtn2: 'Continue Paying Previous Order',
    closeMyCard: 'Close current page',
    empty: 'Please log in your account to check the items.',
    coupon: 'Coupon',
    check: 'Check',
    orderTitle: 'Confirm Payment',
    checkCoupon: 'Check Coupons',
    orderCancel: 'Payment canceled',
    orderListTitle: 'Web payment history',
    orderListSecTitle: 'Payment history available for up to one year',
    orderListEmpty: 'No payment history',
    orderListPayWay: 'Payment',
    orderListPayTime: 'Date paid:',
    orderListCreateTime: 'Date ordered:',
    orderListNum: 'Order No.:',
    orderListExtra: 'VAT included',
    orderListCloseTime: 'Close countdown:',
    orderListClose: 'Cancel',
    orderListPay: 'Confirm',
    loadMore: 'More',
    orderTab1: 'All',
    orderTab2: 'Unpaid',
    orderTab3: 'Paid',
    orderTab4: 'Canceled',
    orderTab5: 'Order closed',
    navHome: 'Home',
    navMyOrder: 'My order',
    exchangeCoupon: 'Redeem Coupon',
    exchangeTip: 'Enter Redeem code',
    exchangeCode: 'Redeem code:',
    exchangeCodeTip: 'Please enter the code (16 upper characters and numbers)',
    exchange: 'Confirm',
    hasCoupon: "Congratulations! You've redeemed the coupon.",
    checkExchangeCoupon: 'Check/Redeem',
    share: 'Stackable'
  },
  payRule: {
    title: 'Attention',
    text1: '1. Please be sure to double-check the transaction information you entered. Once the product transaction is complete, this will not be able to transfer to another account.',
    text2: '2. After the purchase is completed, the item will be credited according to the status of the day\'s connection, which will take at least 5 minutes and at most 40 minutes. For limited events, please complete the purchase process one hour prior to the end time of the promotion to prevent the items from being credited to the next day\'s account.',
    text3: '3. If you have any questions, please contact customer service: catcs.contact@gmail.com. Processing time is about 3-5 working days.',
    text4: '',
    text5: '',
    text6: '',
  },
  final: {
    success: 'Thank you!',
    failed: 'Oops',
    goBack: 'Back to Main',
    tips: ''
  },
  rule: {
    title: 'Attention',
    text1: '1. Please be sure to double-check the transaction information you entered. Once the product transaction is complete, this will not be able to transfer to another account.',
    text2: '2. After the purchase is completed, the item will be credited according to the status of the day\'s connection, which will take at least 5 minutes and at most 40 minutes. For limited events, please complete the purchase process one hour prior to the end time of the promotion to prevent the items from being credited to the next day\'s account.',
    text3: '3. If you have any questions, please contact customer service: catcs.contact@gmail.com. Processing time is about 3-5 working days.',
    text4: ''
  }
};
