"use strict";

import Cookies from "js-cookie";

// 事件行为判定cookie
export function getToken(name) {
  return Cookies.get(name);
}

export function setToken(name, value, data) {
  if (data) {
    return Cookies.set(name, value, { expires: data });
  } else {
    return Cookies.set(name, value);
  }
}

export function removeToken(eventKey) {
  return Cookies.remove(eventKey);
}

// Storage存储
const sessionStorage = window.sessionStorage;
const localStorage = window.localStorage;

// sessionStorage存储封装
export function setSessionStorage(key, val) {
  sessionStorage.setItem(key, val);
}

export function getSessionStorage(key) {
  return sessionStorage.getItem(key);
}
export function removesessionStorage(key) {
  return sessionStorage.removeItem(key);
}

// localStorage存储封装
export function setLocalStorage(key, val) {
  localStorage.setItem(key, val);
}
export function getLocalStorage(key) {
  return localStorage.getItem(key);
}
export function removeLocalStorage(key) {
  return localStorage.removeItem(key);
}

// 种植cookie
export function setCrossDomainCookie(name, value, days) {
  // console.log("name, value, days", name, value, days);
  var date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  var expires = "expires=" + date.toUTCString();
  // var domain = "";
  var domain = "domain=.catfantasygame.com";
  document.cookie =
    name + "=" + value + "; " + expires + "; " + domain + "; path=/";
}

// 获取cookie
export function getCookie(name) {
  const pattern = new RegExp(`(?:^|; )${encodeURIComponent(name)}=([^;]*)`);
  const matches = document.cookie.match(pattern);
  return matches ? decodeURIComponent(matches[1]) : "";
}

// 删除cookie
// 将有效期设置成昨天--该cookie就会自动删除
export function removeCookies(name) {
  setCrossDomainCookie(name, "0", -1)
}
