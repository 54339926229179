// 根据电脑来判断语言
module.exports = {
  toast: {
    agreementTips: "이용약관을 읽고 동의하세요",
    nopsw: "비밀번호 입력",
    verifypAccount: "아이디 입력 형식에 오류가 있습니다",
    noAccount: "아이디 입력",
    gotoGame: "로그인",
    noPhone: "이메일을 입력하세요.",
    verifyphoneTips: "정확한 이메일을 입력하세요.",
    verifycodeTips: "인증 코드를 입력하세요.",
    verifypswTips: "비밀번호 입력(6~18자, 띄어쓰기 불가)",
    newverifypswTips: "새 비밀번호 입력(6~18자, 띄어쓰기 불가)",

    noName: "이름을 입력하세요",
    noID: "",
    verifyIDTips: "",
    confirmPswTips: "동일한 비밀번호를 입력하셔야 합니다.",

    accountPlaceholder: "이메일 입력",
    phonePlaceholder: "이메일 입력",
    pswPlaceholder: "비밀번호 입력",
    newPswPlaceHolder: "새 비밀번호 입력",
    confirmPswPlaceholder: "비밀번호 재입력",
    IDPlaceholder: "",
    namePlaceholder: "",
    verifycodePlaceholder: "인증 코드 입력",
    findAccountPlaceHolder: "찾고 싶은 아이디를 입력하세요.",

    findAccountTxt1: "비밀번호를 재설정할 아이디를 입력하세요.",
    findAccountTxt2: "클릭하여 인증코드를 받으세요. 인증코드는 연동하신 ",
    verifycodeBtn: "발송",
    verifycodeBtn2: "재발송",
    gotoRegister: "가입",
    findAccount: "비밀번호 찾기",
    gotoAccountLogin: "아이디로 로그인",
    agreementText: "읽고 동의함",
    userAgreementName: "이용약관",
    privacyAgreementName: "개인정보 보호지침",
    agreementText2: "가입 시 해당 약관에 동의하시는 것으로 간주합니다",
    loginErrorTips: "로그인 상태 오류, 보안 인증을 완료해야 합니다",
    phoneVerify: "연동된 이메일 인증",
    and: "(와)과",
    comfirmBtn1: "제출",
    comfirmBtn2: "확인",
    comfirmBtn3: "가입",
    comfirmBtn4: "돌아가기",
    comfirmBtn5: "취소",
    comunicaton1: "이메일",
    comunicaton2: "이메일",
    changepswTxt1: "아이디 설정",
    changepswTxt2: "의 새 비밀번호",

    bannerGameTxt1: "안내",
    bannerGameTxt2: "",
    bannerGameTxt3: "",

    agreementTips1: "이용약관이 변경되었습니다. 자세히 읽고 클릭하여 동의하신 후 본 제품을 사용하실 수 있습니다",
    agreementTips2: "약관을 읽고 동의한 후, 게임을 이용하실 수 있습니다",
    agreementTips3: "거절",
    agreementTips4: "동의",
    agreementTips5: "약관을 자세히 읽으신 후 동의 버튼을 클릭해 주세요",
    agreementTips6: "약관을 읽고 동의한 후, 로그인하실 수 있습니다",

    bindIdTxt1: "",
    bindIdTxt2: "",

    bindPhoneTxt1: "연동하실 이메일을 입력해 주세요:",

    loginErrorTxt1: "로그인 상태 오류, 보안 인증을 완료해야 합니다",
    loginErrorTxt2: "이메일 인증:",

    successTitle: "게임에 오신 걸 환영합니다",
  },
  myCard: {
    titleAccount: "계정 정보",
    account: "계정",
    activeAccount: "현재 계정",
    password: "비밀번호",
    titlePayWay: "결제 방식",
    titlePayAccount: "상품 선택",
    titlePayList: "프리즘 리스트",
    myCardPoint: "MyCard 포인트",
    myCardGiftNum: "프리즘(개)",
    submit: "제 출",
    change: "충전 교환",
    logout: "로그아웃",
    gameRole: "캐릭터",
    noGameRole: "연동된 캐릭터가 없습니다. 계정을 전환한 후 다시 시도하세요",
    point: 'pt',
    cardNum: '卡號',
    cardPass: '卡密',
    placeholderCardNum: '請輸入卡號',
    placeholderCardPass: '請輸入密碼',
    extraGift: '추가 증정',
    time: '카운트다운',
    timeout: '만료',
    payWay1: '',
    payWay2: '',
    payWay3: '',
    payWay4: '',
    secTitle1: '이벤트 한정',
    secTitle2: '스페셜 캐시백',
    secTitle3: '전용 보너스',
    secTitle4: '상시 상점',
    failed: '해당 조작이 실패하였습니다',
    tip: '홈페이지 충전 시, 금액별 첫 충전 캣츠아이 2배 보상은 인게임 첫 충전 시와 공유됩니다.',
    days: '일',
    pay1: '결제를 진행해주세요',
    pay2: '결제 완료',
    pay3: '결과를 확인할 수 없습니다',
    pay4: '결제를 완료하셨다면, 잠시 후 인게임에서 수령하실 수 있습니다',
    pay5: '잠시 후에 인게임에서 수령하실 수 있습니다',
    loginFirst: '게임 계정으로 로그인해 주세요',
    extraSend: '추가 증정:',
    extraTitle: '프리즘 수량 입력',
    stock: '현재 보유 중:',
    otherNum: '기타 수량',
    chooseCoupon: '쿠폰 선택',
    loginTips: '로그인 후 확인하실 수 있습니다',
    noCoupon: '사용하실 수 있는 쿠폰이 없습니다',
    expireAt: '유효 기간:',
    disCount: 'off',
    useType: '{amount}원 이상 사용 가능',
    proInfo: '상품 정보:',
    proNum: '상품 수량:',
    roleInfo: '캐릭터 정보：',
    proPrice: '상품 원가:',
    disCountPrice: '할인 금액:',
    totalPrice: '합계:',
    inputNum: '충전 수량 입력',
    extraTips: '안내: 입력 가능한 수량은 6,480개 이상이며, 10의 배수로 입력해야 하고, 회당 최대 99,990를 초과할 수 없습니다.',
    chooseRole: '캐릭터 선택',
    maxNum: '최대 할인',
    couponTips: '현재 결제 중인 주문에 쿠폰을 사용하셨습니다. 결제를 취소하고 쿠폰을 반환하시겠습니까?',
    couponTips2: '현재 결제 중인 주문에 쿠폰을 사용하셨습니다. 결제를 취소하고 쿠폰을 반환하시겠습니까? (반환된 쿠폰은 추후 주문에서 계속 사용할 수 있습니다)',
    useCoupon: '사용한 쿠폰：',
    originalAmount: '아이템 원가：',
    productSubject: '아이템 정보：',
    payAmount: '결제 금액：',
    couponBtn1: '결제 취소 및 쿠폰 반환',
    couponBtn2: '이전 주문 계속 결제',
    closeMyCard: '닫기',
    empty: '상품 확인을 위해 먼저 게임 계정으로 로그인 하세요~',
    coupon: '쿠폰',
    check: '확인',
    orderTitle: '결제 내용 확인',
    checkCoupon: '쿠폰 확인',
    orderCancel: '결제가 취소됐습니다',
    orderListTitle: '홈페이지 결제 기록 조회',
    orderListSecTitle: '최대 1년 이내의 기록을 조회할 수 있습니다',
    orderListEmpty: '결제 기록 없음',
    orderListPayWay: '결제',
    orderListPayTime: '결제 시간:',
    orderListCreateTime: '주문 시간:',
    orderListNum: '주문 번호:',
    orderListExtra: '부가세 포함',
    orderListCloseTime: '결제 종료까지 남은 시간:',
    orderListClose: '결제 취소',
    orderListPay: '결제하러가기',
    loadMore: '더보기',
    orderTab1: '전체',
    orderTab2: '미결제',
    orderTab3: '결제 완료',
    orderTab4: '취소 완료',
    orderTab5: '주문 종료',
    navHome: '홈',
    navMyOrder: '내 주문',
    exchangeCoupon: '쿠폰 교환',
    exchangeTip: '교환 코드 입력',
    exchangeCode: '교환 코드:',
    exchangeCodeTip: '교환 코드를 입력하세요(영문, 숫자를 포함한 16자)',
    exchange: '교환',
    hasCoupon: '쿠폰을 획득하셨습니다',
    checkExchangeCoupon: '쿠폰 교환&조회',
    share: '중첩 가능'
  },
  payRule: {
    title: '주의사항',
    text1: '1. 첫 프리즘 구매 시, 금액별로 캣츠아이를 추가로 지급하는 보너스는 웹상점과 인게임 충전에 관계없이 첫 충전 시에만 지급됩니다(자체 수량 입력 프리즘 충전 시에는 해당 보너스가 지급되지 않습니다)',
    text2: '2. 웹상점 상품 결제도 충전 포인트를 받을 수 있으며 충전 포인트는 결제한 프리즘 수량과 동일합니다. 추가로 증정해 드리는 프리즘 10%는 충전 포인트에 적립되지 않습니다.',
    text3: '3. 쿠폰을 적용한 결제 도중 결제를 완료하지 않고 나가면, 쿠폰은 사라지지 않지만 쿠폰함에 나타나지 않습니다. 이 경우, 상점에서 임의의 상품을 선택하여 결제를 누르면 "결제를 취소하고 쿠폰을 반환하시겠습니까?"라는 팝업창이 뜹니다. 이때 "결제 취소 및 쿠폰 반환"를 클릭해 주세요. 그러면 쿠폰이 정상적으로 쿠폰함에 표시됩니다.',
    text4: '4. 구매 시 기존에 입력하신 거래관련 정보를 확인하시기 바랍니다. 결제가 성공한 후, 다른 계정으로 이전할 수 없으니 주의하시기 바랍니다.',
    text5: '5. 구매가 성공한 후, 아이템은 당일 로그인 시 지급되며, 최소 5분의 지연 타임이 있고, 최대 40분까지 지연될 수 있습니다. 만약 기간 한정 이벤트에 참여하신다면, 아이템 지급 시간이 다음날로 계산되지 않도록, 이벤트가 종료하기 1시간 전에 구매를 완료해주시기 바랍니다.',
    text6: '6. 문의사항이 있으시면, 고객센터에 문의해주시기 바랍니다. 고객센터 이메일: cat-contact_kr@funtoygame.com. 영업일 3~5일 이내에 답변을 드릴 수 있습니다.'
  },
  final: {
    success: '감사합니다!',
    failed: '결제 실패',
    goBack: '홈화면으로 돌아가기',
    tips: '보상을 발송했습니다, 인게임 우편에서 수령해 주세요'
  },
  rule: {
    title: '주의사항',
    text1: '1. 구매 시 기존에 입력하신 거래관련 정보를 확인하시기 바랍니다. 결제가 성공한 후, 다른 계정으로 이전할 수 없으니 주의하시기 바랍니다.',
    text2: '2. 구매가 성공한 후, 아이템은 당일 로그인 시 지급되며, 최소 5분의 지연 타임이 있고, 최대 40분까지 지연될 수 있습니다. 만약 기간 한정 이벤트에 참여하신다면, 아이템 지급 시간이 다음날로 계산되지 않도록, 이벤트가 종료하기 1시간 전에 구매를 완료해주시기 바랍니다.',
    text3: '3. 문의사항이 있으시면, 고객센터에 문의해주시기 바랍니다. 고객센터 이메일: cat-contact_kr@funtoygame.com. 영업일 3~5일 이내에 답변을 드릴 수 있습니다.',
  }
}
