<template>
  <div class="payPage">
    <div class="pay_box">
      <router-view :key="key()" />
    </div>
  </div>
</template>

<script>
import { alipay } from "@/api/pay"
import configData from "@/utils/configData"
export default {
  data () {
    return {
      select: 1,
    }
  },
  methods: {
    key () {
      return this.$route.path
    }
  }
}
</script>

<style lang="scss" scoped>
.payPage {
  width: 100%;
  height: 100vh;
  min-width: 900px;
  background: rgba(0, 0, 0, 0.3);
  position: relative;
  .pay_box {
    width: 700px;
    height: 620px;
    background-color: #fff;
    margin: 0 auto;
    border-radius: 6px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3D(-50%, -50%, 0);
    padding: 30px 50px;
    box-sizing: border-box;
  }
}
</style>