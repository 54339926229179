<template>
  <h5-login
    class="login"
    :base-url="baseUrl"
    :config-data="configData"
    :callback_uri="callback_uri"
    :client_game_app_id="client_game_app_id"
    @loginSuccess="loginSuccess"
    @close="close"
  />
</template>

<script>
import {
  setCrossDomainCookie,
  setLocalStorage,
  setToken,
} from "@/utils/stage";

export default {
  name: "vue-login",
  props: {
    client_game_app_id: {
      default: "",
    },
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_API,
      username: "",
      configData: {
        app_id: "",
        platform: "web",
        kid: "123456",
        sdk_code: 3,
        lang:
          this.$i18n.locale === "en"
            ? "en-us"
            : this.$i18n.locale === "kr"
            ? "ko-kr"
            : "zh-tw",
      },
      callback_uri: window.location.origin + window.location.pathname,
    };
  },
  created() {
    this.configData.app_id = this.$appId;
  },
  methods: {
    // new
    loginSuccess(data) {
      let username = "";
      if (data.user_entity.email && data.user_entity.email.length > 0) {
        if (data.user_entity.has_set_username === 1) {
          username = data.user_entity.username;
        } else {
          username = data.user_entity.email;
        }
      } else {
        if (data.user_entity.nickname && data.user_entity.nickname.length > 0) {
          username = data.user_entity.nickname;
        } else {
          username = data.user_entity.username;
        }
      }
      this.$parent.username = username;
      setLocalStorage("loginAccount", username);
      setLocalStorage("accountStorage", JSON.stringify(data.user_entity));
      setToken("login", "success", 7);
      setCrossDomainCookie("access_token", data.user_entity.access_token, 1)
      setCrossDomainCookie("username", username, 1)
      // this.$router.push({ path: "/payOnline" });
      window.location.href = window.location.origin + "/payOnline";
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
