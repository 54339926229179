// 进入游戏
import {
  setSessionStorage,
  setLocalStorage,
  getSessionStorage,
  getLocalStorage,
  getToken,
  removeLocalStorage,
} from "./stage";
import { appQuery } from "../api/init";
// import configData from './configData'
import store from "@/store";
import router from "@/router";
export function getInit() {
  if (window.vuplex) {
    var msg = {
      ErrorCode: 0,
    };
    window.vuplex.postMessage(msg);
  }
}
export function gotoGame(data) {
  if (window.vuplex) {
    const msg = {
      ErrorCode: 2,
      Msg: JSON.stringify(data),
    };
    window.vuplex.postMessage(msg);
    console.log("成功了666");
  }
}
export function closeGame() {
  const msg1 = {
    ErrorCode: 1,
  };
  window.vuplex.postMessage(msg1);
}
export function entergame() {
  const msg = {
    ErrorCode: 13,
  };
  window.vuplex.postMessage(msg);
}
export function gamefcm() {
  const msg = {
    ErrorCode: 100,
  };
  window.vuplex.postMessage(msg);
}
export function kickout() {
  const msg = {
    ErrorCode: 14,
    state: "AntiAddiction",
  };
  window.vuplex.postMessage(msg);
}
export function gameConfig() {
  let configData = {};
  if (window.location.host.indexOf("fungoglobal") > -1) {
    // 正式服
    configData = {
      platform: "pc",
      app_id: "904e9b2b0396ba6bdc2a8bb55b58398c",
      //   app_id: "d9d4b11a7d31c4fed63da40076dc2a59",
      kid: "666666666",
      lang: "zh-cn",
      sdk_code: 3
    };
  } else {
    // 测试服
    configData = {
      platform: "pc",
      //   app_id: "78b6f4a61428e33677a1781d3603220e",
      app_id: "3b0c348056a155bdbf415939a2c2de2c",
      // app_id: "05eb00895c7cdda96dc3974300fc03cc",
      //   app_id: "d9d4b11a7d31c4fed63da40076dc2a59",
      kid: "6666",
      lang: "zh-tw",
      sdk_code: 3
    };
  }
  setSessionStorage("message", JSON.stringify(configData));
  setLocalStorage("message", JSON.stringify(configData));
  store.dispatch("info/getGameInfo").then((data) => {
    // if (getToken("login") == "success") {
    //   router.push("/safetySettings");
    // } else {
    //   router.push("/");
    //   removeLocalStorage("accountStorage");
    // }
  });
}

function confirmAgreement() {
  // router.push("/login")
  //新请求的与本地做对比分别判断是否跟新协议(0初始未读，1已读 2更新未读)
  const dataconfigInit = JSON.parse(getSessionStorage("configInit"));
  let userAgreementFlag = "0";
  if (JSON.parse(getLocalStorage("configInit")) === null) {
    router.push("/userAgreement");
    userAgreementFlag = 0;
  } else {
    const agreement_user_version = JSON.parse(
      getLocalStorage("configInit")
    ).agreement_user_version;
    if (agreement_user_version !== dataconfigInit.agreement_user_version) {
      router.push("/userAgreement");
      userAgreementFlag = 2;
    } else {
      router.push("/login");
      userAgreementFlag = 1;
    }
  }
  setLocalStorage("userAgreementFlag", userAgreementFlag);
}
