<template>
  <div :class="[isH5 ? 'payH5' : 'payWeb']">
    <div class="head">
      <div class="top-nav">
        <div class="logo" v-if="isH5">
          <img v-if="isEn" src="@/assets/images/mycardH5/logo-en.png" alt="" />
          <img
            v-else-if="isTw"
            src="@/assets/images/mycardH5/logo-tw.png"
            alt=""
          />
          <img
            v-else-if="isKr"
            src="@/assets/images/mycardH5/logo-kr.png"
            alt=""
            class="kr-logo"
          />
          <img v-else src="@/assets/images/mycardH5/logo-cn.png" alt="" />
        </div>
        <div class="logo" v-else>
          <img v-if="isEn" src="@/assets/images/mycard/logo-en.png" alt="" />
          <img
            v-else-if="isTw"
            src="@/assets/images/mycard/logo-tw.png"
            alt=""
          />
          <img
            v-else-if="isKr"
            src="@/assets/images/mycard/logo-kr.png"
            alt=""
            class="kr-logo"
          />
          <img v-else src="@/assets/images/mycard/logo-cn.png" alt="" />
        </div>
        <div class="right">
          <a
            v-if="hasLogin && !isOrderList"
            href="/orderList"
            :target="isH5 ? '' : '_blank'"
          >
            <img v-if="isH5" src="@/assets/images/mycard/orderH5.png" alt="" />
            <img v-else src="@/assets/images/mycard/order.png" alt="" />
            {{ $t("myCard.navMyOrder") }}
          </a>
          <a
            v-if="hasLogin && isOrderList"
            @click="goPay"
            :target="isH5 ? '' : '_blank'"
          >
            <img v-if="isH5" src="@/assets/images/mycard/homeH5.png" alt="" />
            <img v-else src="@/assets/images/mycard/home.png" alt="" />
            {{ $t("myCard.navHome") }}
          </a>
          <!--          <a href="#"><img src="@/assets/images/mycard/question.png" alt="" />問題諮詢</a>-->
          <!--          <div class="select">-->
          <!--            <p @click.stop="changeList" :class="hasLogin ? 'disable' : ''">-->
          <!--              <img src="@/assets/images/mycard/earth.png" alt="" />-->
          <!--              {{ chooseProjectName }}-->
          <!--            </p>-->
          <!--            <ul v-show="showProjectList">-->
          <!--              <li-->
          <!--                v-for="(item, index) in projectList"-->
          <!--                :key="index"-->
          <!--                @click="chooseProject(item)"-->
          <!--              >-->
          <!--                {{ item.name }}-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--          </div>-->
          <div
            v-if="username && username.length > 0 && hasLogin"
            class="username"
          >
            <img
              v-if="isH5"
              class="icon-user"
              src="@/assets/images/mycardH5/icon-user.png"
              alt=""
            />
            <span class="username-text">{{ username }}</span>
            <i class="arrow"></i>
            <span class="logout" @click="loginOut">{{
              $t("myCard.logout")
            }}</span>
          </div>
          <span v-else class="top-login" @click="showLogin = true">{{
            $t("toast.gotoGame")
          }}</span>
        </div>
      </div>
      <div class="banner">
        <img v-if="isH5" src="@/assets/images/mycardH5/top-bg.png" alt="" />
        <img v-else src="@/assets/images/mycard/pc-kv2.png" alt="" />
      </div>
    </div>
    <vue-login
      v-if="showLogin"
      @close="close"
      :client_game_app_id="client_game_app_id"
    ></vue-login>
    <router-view
      @flagChange="flagChange"
      @loginSuccess="loginSuccess"
      :client_game_app_id="client_game_app_id"
      :is-h5="isH5"
    />
    <div class="web-intro" v-if="!isH5 && isKr && !isOrderList">
      <div class="web-intro-main">
        <div class="left">
          <img src="@/assets/images/mycard/fundoll.png" alt="" />
          <div class="links" style="font-size: 16px">
            <a
              href="https://kr.catfantasygame.com/privacypolicy.html?agreement_id=54&amp;section=2&amp;title=개인정보"
              target="_black"
              style="margin-right: 0"
              >이용 약관</a
            >
            <i style="margin: 0 10px; color: #fa8d9e">|</i>
            <a
              href="https://kr.catfantasygame.com/privacypolicy.html?agreement_id=54&amp;section=3&amp;title=약관"
              target="_black"
              >개인정보 처리방침</a
            >
          </div>
          <p>FUNTOY GAMES PTE. LTD. All rights reserved.</p>
          <p>FUNDOLL GLOBAL LIMITED All rights reserved.</p>
          <p>
            FUNGO GLOBAL PTE.LTD. 주소: 60 PAYA LEBAR ROAD #11-01 PAYA LEBAR
            SQUARE SINGAPORE (409051)
          </p>
          <p>
            사업등록자번호: 20181552C 대표번호: 8730 8068/8660 2503 대표이름:
            Zhou Yongfeng
          </p>
        </div>
        <div class="right">
          <div class="each mb">
            <img src="@/assets/images/mycard/intro-icon1.png" alt="" />
            <div class="text">
              <p>안전성</p>
              <span>공식 결제 센터</span>
            </div>
          </div>
          <div class="each mb">
            <img src="@/assets/images/mycard/intro-icon2.png" alt="" />
            <div class="text">
              <p>편의성</p>
              <span>구매 수량 설정</span>
            </div>
          </div>
          <div class="each">
            <img src="@/assets/images/mycard/intro-icon3.png" alt="" />
            <div class="text">
              <p>혜택</p>
              <span>푸짐한 아이템 추가 증정 및 할인쿠폰</span>
            </div>
          </div>
          <div class="each">
            <img src="@/assets/images/mycard/intro-icon4.png" alt="" />
            <div class="text">
              <p>고효율</p>
              <span>구매 후 빠른 지급</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a
      :href="link + access_token"
      target="_blank"
      class="go-lottery"
      v-if="!isH5 && isKr && showLink"
    >
      <img src="@/assets/images/kr-lottery.png" alt="" />
    </a>
    <a
      :href="link + access_token"
      target="_blank"
      class="go-lottery-h5"
      v-if="isH5 && isKr && showLink"
    >
      <img src="@/assets/images/kr-lottery-h5.png" alt="" />
    </a>
  </div>
</template>

<script>
import {
  removeLocalStorage,
  setLocalStorage,
  getLocalStorage,
  removeToken,
  removesessionStorage,
  removeCookies,
  getCookie,
} from "@/utils/stage";
import { getAppShort } from "@/api/mycard";
import vueLogin from "../views/Home/components/vue-login";
import axios from "axios";

export default {
  components: { vueLogin },
  data() {
    return {
      backFlag: false,
      controlFlag: false,
      showProjectList: false,
      projectId: "",
      chooseProjectName: "",
      gameAppId: this.$route.query.app_id,
      hasLogin: false,
      client_game_app_id: "b2b2d5a215af171cc8935b1d237e0635", // 游戏平台：android
      isH5: false,
      isEn: this.$isEn,
      isTw: this.$isTw,
      isKr: this.$isKr,
      showLogin: false,
      username: getLocalStorage("loginAccount") || "",
      isOrderList: this.$route.path.indexOf("/orderList") > -1,
      access_token: "",
      link:
        window.location.href.indexOf("passport-web-pay.17atv.com") > -1 ||
        process.env.NODE_ENV === "development"
          ? "https://cat-claw-crane.17atv.com/lotterykr/index?access_token="
          : "https://paywheelkr.catfantasygame.com/lotterykr/index?access_token=",
      showLink: false,
    };
  },
  watch: {
    $route: function (to, from) {
      if (
        this.$route.path.indexOf("/accountlogin") > -1 ||
        this.$route.path.indexOf("/checkuser") > -1
      ) {
        this.backFlag = true;
      } else {
        this.backFlag = false;
      }
    },
  },
  created() {
    if (this.$route.query.lang) {
      this.$router.push("/accountLogin");
    }
    this.isH5 = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
      navigator.userAgent
    );
    if (this.gameAppId && this.gameAppId.length > 0) {
      this.getAppShort();
    } else {
      this.projectId = this.$projectId;
      this.client_game_app_id = this.$client_game_app_id;
      this.chooseProjectName = this.$gameName;
      setLocalStorage("projectId", this.$projectId);
    }

    // lottery
    if (getCookie("access_token")) {
      this.access_token = getCookie("access_token");
    } else {
      this.access_token = getLocalStorage("accountStorage")
        ? JSON.parse(getLocalStorage("accountStorage")).access_token
        : "";
    }
    const baseUrl =
      window.location.href.indexOf("passport-web-pay.17atv.com") > -1 ||
      process.env.NODE_ENV === "development"
        ? "https://claw-crane.17atv.com/api"
        : "https://api-paywheelkr.catfantasygame.com/api";
    axios
      .post(baseUrl + "/activity/detail", {
        activityMode: 3,
      })
      .then((res) => {
        if (
          res.data.data.activity.started &&
          res.data.data.activity.started === 1
        ) {
          this.showLink = true;
        }
      });
  },
  mounted() {
    document.addEventListener("click", this.handleClose);
  },
  methods: {
    close() {
      this.showLogin = false;
    },
    handleClose() {
      this.showProjectList = false;
    },
    loginSuccess(data, name) {
      this.hasLogin = data;
      this.username = name;
    },
    changeList() {
      if (!this.hasLogin) {
        this.showProjectList = !this.showProjectList;
      }
    },
    flagChange(data) {
      this.controlFlag = data;
    },
    getAppShort() {
      getAppShort({ game_app_id: this.gameAppId }).then((res) => {
        this.projectId = res.data.project_id;
        setLocalStorage("projectId", res.data.project_id);
      });
    },
    chooseProject(item) {
      this.showProjectList = false;
      setLocalStorage("projectId", item.projectId);
      this.chooseProjectName = item.name;
      this.projectId = item.projectId;
    },
    loginOut() {
      removeToken("login");
      removeCookies("access_token");
      removeCookies("username");
      removesessionStorage("accountStorage");
      removeLocalStorage("accountStorage");
      removeLocalStorage("loginAccount");
      removeLocalStorage("chooseProduct");
      removeLocalStorage("pointPrice");
      removeLocalStorage("pointNum");
      removeLocalStorage("pointExtraNum");
      removeLocalStorage("couponCurrentCode");
      this.hasLogin = false;
      this.$router.push("/");
    },
    goPay() {
      window.open(window.location.origin + "/payOnline", "payOnline");
    },
  },
};
</script>
