import { getconfig } from "@/api/init";
import { setSessionStorage, getSessionStorage } from "@/utils/stage";

const state = {
  yd_product_id: "",
  login_ways: [],
  google_config: {},
  captcha_id: "",
};
const mutations = {
  SET_PRODUCT: (state, token) => {
    state.yd_product_id = token;
  },
  SET_LOGINWAYS: (state, token) => {
    state.login_ways = token;
  },
  SET_GOOGLECONFIG: (state, token) => {
    state.google_config = token;
  },
  SET_CAPTCHAID: (state, token) => {
    state.captcha_id = token;
  },
};
const actions = {
  getGameInfo({ commit }) {
    return new Promise((resolve, reject) => {
      let YOUR_PRODUCT_NUMBER = "";
      let wm = null;
      const configData = getSessionStorage("message");
      getconfig(configData)
        .then((res) => {
          setSessionStorage("configInit", JSON.stringify(res.data));
          commit("SET_PRODUCT", res.data.yd.product_id);
          commit("SET_CAPTCHAID", res.data.yd.captcha_id);
          commit("SET_LOGINWAYS", res.data.login_ways);
          YOUR_PRODUCT_NUMBER = res.data.yd.product_id;
        })
        .then(() => {
          // 初始化SDK，只需初始化一次
          if (YOUR_PRODUCT_NUMBER) {
            initWatchman({
              productNumber: YOUR_PRODUCT_NUMBER,
              onload: function (instance) {
                wm = instance;
                window.wm = wm;
              },
            });
          } else {
            window.wm = null;
          }
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
