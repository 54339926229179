
import md5 from 'js-md5'
import axios from 'axios'
import {getSessionStorage,getLocalStorage}from './stage'

export function	getburiedPoint(eventContent,eventId,game_uuid,app_version_code){
  const msg=JSON.parse(getLocalStorage('message'))
  const timestamp = Date.parse(new Date()).toString().substr(0,10)
  const salt='8e5367f42b66cd5e881f69738eeed4d2'
const dataName =['android_id','app_version','channel','event_content','event_id','event_time','game_uuid','idfa','imei','merchant','network','open_id','os_version','platform','player_create_time','player_id','player_level','server','udid','user_id','version']
  const  dataObj={
    android_id:'',
    app_version:msg.app_version_code,
    channel:msg.channel,
    event_content:eventContent,
    event_id:eventId,
    event_time:timestamp,
    game_uuid:game_uuid,
    idfa:msg.idfa,
    imei:'',
    merchant:'',
    network:'',
    open_id:'',
    os_version:'',
    platform:'3',
    player_create_time:'',
    player_id:'',
    player_level:'',
    server:'',
    udid:'',
    user_id:'',
    version:''
  }
let dataSting=''
for (const i in dataName){
  const itemName=dataName[i]
  const item =itemName+'='+dataObj[itemName]
  dataSting=dataSting+'&'+item
}
dataSting=dataSting+'&'+salt
const md5Sign=md5(dataSting.slice(1))
let data=Object.assign(JSON.parse(JSON.stringify(dataObj)), {sign:md5Sign})
axios.post('//tmp-new-data-event.fantanggame.com/event',data).then(result =>{
      // console.log(result)
    })
  }