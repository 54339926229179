<template>
  <div class="toast"
       v-show="visible">
    {{ message }}
  </div>
</template>

<script>
export default {
  name: 'toast',
  data () {
    return {
      message: '', // 消息文字
      duration: 1200, // 显示时长，毫秒
      closed: false, // 用来判断消息框是否关闭
      timer: null, // 计时器
      visible: false // 是否显示
    }
  },
  mounted () {
    this.startTimer()
  },
  watch: {
    closed (newVal) {
      if (newVal) {
        this.visible = false
        this.destroyElement()
      }
    }
  },
  methods: {
    destroyElement () {
      this.$destroy()
      this.$el.parentNode.removeChild(this.$el)
    },
    startTimer () {
      this.timer = setTimeout(() => {
        if (!this.closed) {
          this.closed = true
          clearTimeout(this.timer)
        }
      }, this.duration)
    }
  }
}
</script>

<style lang="scss" scoped>
.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3D(-50%, -50%, 0);
  min-width: 250px;
  padding: 15px 30px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  text-align: center;
  line-height: 26px;
  font-size: 22px;
  border-radius: 6px;
}
@media (min-width: 1600px) {
  .toast {
    font-size: 22px;
  }
}
@media (min-width: 1200px) and (max-width:1599px) {
  .toast {
    font-size: 20px;
  }
}
@media screen and (max-width:1199px) {
  .toast {
    font-size: 18px;
  }
}
</style>
