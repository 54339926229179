<template>
  <div class="password_box">
    <div class="accont_header">
      <span class="passIconfont  goback icon-ftfanhui fl"
            @click="back"></span>
      <!-- <span class="passIconfont  close icon-ftshanchu fr"
            @click="close"></span> -->
    </div>
    <div v-show="!controflag">
      <div class="ft_logo">
        <img src="../../assets/images/ft_logo@1x.png"
             alt="">
      </div>
      <router-view :key="key()"
                   @flagChange="flagChange" />
    </div>
    <agreement v-show="controflag"
               @flagChange="flagChange" />
  </div>
</template>

<script>
import agreement from '../Home/components/agreement.vue'
import { closeGame } from '@/utils/gameapi'
export default {
  components: {
    agreement,
  },
  data() {
    return {
      backFlag: true,
      controflag: false,
    }
  },
  methods: {
    flagChange(data) {
      this.controflag = data
    },
    key() {
      return this.$route.path
    },
    close() {
      closeGame()
    },
    back() {
      this.$router.go(-1) //返回上一层
    },
  },
}
</script>

<style lang="scss" scope>
input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #dcdcdc !important;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #dcdcdc !important;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #dcdcdc !important;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #dcdcdc !important;
}
@media (min-width: 1600px) {
  .password_box {
    width: 100%;
    min-height: 100vh;
    background: #f1f1f1;
    .ft_logo {
      width: 270px;
      min-width: 240px;
      margin: 0 auto;
      margin-top: 30px;
      margin-bottom: 19px;
    }
  }
  .accont_header {
    width: 100%;
    height: 70px;
    background: #fff;
    border-radius: 6px;
    padding: 0 61px;
    box-sizing: border-box;
    .goback {
      font-size: 31px !important;
      line-height: 7px;
      cursor: pointer;
    }
    .close {
      font-size: 31px;
      line-height: 70px;
      cursor: pointer;
    }
  }
}
@media (min-width: 1200px) and (max-width:1599px) {
  .password_box {
    width: 100%;
    height: 100vh;
    background: #f1f1f1;
    .ft_logo {
      width: 270 * 0.85px;
      min-width: 240 * 0.85px;
      margin: 0 auto;
      margin-top: 30 * 0.85px;
      margin-bottom: 19 * 0.85px;
    }
  }
  .accont_header {
    width: 100%;
    height: 70 * 0.85px;
    background: #fff;
    border-radius: 6 * 0.85px;
    padding: 0 61 * 0.85px;
    box-sizing: border-box;
    .goback {
      font-size: 31 * 0.85px !important;
      line-height: 7 * 0.85px;
      cursor: pointer;
    }
    .close {
      font-size: 31 * 0.85px;
      line-height: 70 * 0.85px;
      cursor: pointer;
    }
  }
}
@media (min-width: 500px) and (max-width:1199px) {
  .password_box {
    width: 100%;
    height: 100vh;
    background: #f1f1f1;
    .ft_logo {
      width: 270 * 0.7px;
      min-width: 240 * 0.7px;
      margin: 0 auto;
      margin-top: 30 * 0.7px;
      margin-bottom: 19 * 0.7px;
    }
  }
  .accont_header {
    width: 100%;
    height: 70 * 0.7px;
    background: #fff;
    border-radius: 6 * 0.7px;
    padding: 0 61 * 0.7px;
    box-sizing: border-box;
    .goback {
      font-size: 31 * 0.7px !important;
      line-height: 7 * 0.7px;
      cursor: pointer;
    }
    .close {
      font-size: 31 * 0.7px;
      line-height: 70 * 0.7px;
      cursor: pointer;
    }
  }
}
@media screen and (max-width:499px) {
  .password_box {
    width: 100%;
    height: 100vh;
    background: #f1f1f1;
    .ft_logo {
      width: 270 * 0.6px;
      min-width: 240 * 0.6px;
      margin: 0 auto;
      margin-top: 30 * 0.6px;
      margin-bottom: 19 * 0.6px;
    }
  }
  .accont_header {
    width: 100%;
    height: 70 * 0.6px;
    background: #fff;
    border-radius: 6 * 0.6px;
    padding: 0 61 * 0.6px;
    box-sizing: border-box;
    .goback {
      font-size: 31 * 0.6px !important;
      line-height: 7 * 0.6px;
      cursor: pointer;
    }
    .close {
      font-size: 31 * 0.6px;
      line-height: 70 * 0.6px;
      cursor: pointer;
    }
  }
}
</style>
