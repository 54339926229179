<template>
  <div class="agreement_box">
    <div class="agreement_content_box">
      <div class="ft_logo"></div>
      <div v-if="backFlag"
           class="close passIconfont  icon-ftshanchu"
           @click="close"></div>
      <div class="agreement_content">
        <!-- <router-view :key="key()" /> -->
        <usercontent @controFlag="controFlag" />
      </div>
    </div>
  </div>
</template>

<script>
import { closeGame } from '@/utils/gameapi'
import usercontent from './user.vue'
export default {
  components: {
    usercontent
  },
  data () {
    return {
      backFlag: true
    }
  },
  created () {
    this.init()
  },
  methods: {
    controFlag (data) {
      this.backFlag = data
    },
    init () {
      // gameConfig()
    },
    key () {
      return this.$route.path
    },
    close () {
      this.$emit('flagChange', false)
    },
  }
}
</script>

<style scoped lang="scss">
input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #dcdcdc !important;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #dcdcdc !important;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #dcdcdc !important;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #dcdcdc !important;
}

@media (min-width: 1600px) {
  .close {
    width: 25px;
    height: 25px;
    font-size: 25px;
    position: absolute;
    top: 30px;
    right: 59px;
    cursor: pointer;
    color: #707070;
  }
  .agreement_content_box {
    width: 1376px;
    height: 633px;
    background: #ffffff;
    border-radius: 6px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    .ft_logo {
      width: 272px;
      height: 113px;
      background: url("../../../assets/images/ft_logo@1x.png") no-repeat;
      background-size: contain;
      padding-top: 8px;
      margin: 0 auto;
    }
    .agreement_content {
      // width: 1260px;
      // height: 470px;
      // background: #fbfbfb;
      // margin: 0 auto;
      // margin-top: 16px;
      // overflow-y: auto;
      // &::-webkit-scrollbar {
      //   /*滚动条整体样式*/
      //   width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
      //   height: 4px;
      //   scrollbar-arrow-color: red;
      // }
      // &::-webkit-scrollbar-thumb {
      //   /*滚动条里面小方块*/
      //   border-radius: 5px;
      //   -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      //   background: rgba(0, 0, 0, 0.2);
      //   scrollbar-arrow-color: red;
      // }
      // &::-webkit-scrollbar-track {
      //   /*滚动条里面轨道*/
      //   -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      //   border-radius: 0;
      //   background: rgba(0, 0, 0, 0.1);
      // }
    }
  }
}
@media (min-width: 1200px) and (max-width:1599px) {
  .close {
    width: 25 * 0.8px;
    height: 25 * 0.8px;
    font-size: 25 * 0.8px;
    position: absolute;
    top: 30 * 0.8px;
    right: 59 * 0.8px;
    cursor: pointer;
    color: #707070;
  }
  .agreement_content_box {
    width: 1376 * 0.8px;
    height: 633 * 0.8px;
    background: #ffffff;
    border-radius: 6 * 0.8px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    .ft_logo {
      width: 272 * 0.8px;
      height: 113 * 0.8px;
      background: url("../../../assets/images/ft_logo@1x.png") no-repeat;
      background-size: contain;
      padding-top: 8 * 0.8px;
      margin: 0 auto;
    }
    // .agreement_content {
    //   width: 1260 * 0.8px;
    //   height: 470 * 0.8px;
    //   background: #fbfbfb;
    //   margin: 0 auto;
    //   margin-top: 16 * 0.8px;
    //   overflow-y: auto;
    // }
  }
}
@media screen and (max-width:1199px) {
  .close {
    width: 25 * 0.6px;
    height: 25 * 0.6px;
    font-size: 25 * 0.6px;
    position: absolute;
    top: 30 * 0.6px;
    right: 59 * 0.6px;
    cursor: pointer;
    color: #707070;
  }
  .agreement_content_box {
    width: 1376 * 0.6px;
    height: 633 * 0.6px;
    background: #ffffff;
    border-radius: 6 * 0.6px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    .ft_logo {
      width: 272 * 0.6px;
      height: 113 * 0.6px;
      background: url("../../../assets/images/ft_logo@1x.png") no-repeat;
      background-size: contain;
      padding-top: 8 * 0.6px;
      margin: 0 auto;
    }
    // .agreement_content {
    //   width: 1260 * 0.6px;
    //   height: 470 * 0.6px;
    //   background: #fbfbfb;
    //   margin: 0 auto;
    //   margin-top: 16 * 0.6px;
    //   overflow-y: auto;
    // }
  }
}
</style>
