// import {getconfig} from '@/api/init'
// import configData from "@/utils/configData"
// import{setSessionStorage} from "@/utils/stage"

//   var wm = null;
//   let YOUR_PRODUCT_NUMBER=''
//   getconfig(configData).then((res)=>{
//     setSessionStorage('product_id',res.data.yd.product_id)
//     YOUR_PRODUCT_NUMBER=res.data.yd.product_id
//     }).then(()=>{
//       // 初始化SDK，只需初始化一次
//       initWatchman({
//         productNumber: YOUR_PRODUCT_NUMBER,
//         onload: function (instance) {
//           wm = instance;
//           window.wm =wm
//         }
//       })
//   })
// import store from './store'
import {getInit} from './utils/gameapi'
import {gameConfig} from './api/mycard'
gameConfig()
getInit()

