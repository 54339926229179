import axios from "axios";
import Toast from "@/components/message/Toast";
import {removeLocalStorage, removeToken} from "@/utils/stage";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  headers: {
    "Content-Type": "application/json",
  },
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom errcode
   * Here is just an example
   * You can also judge the status by HTTP Status errcode
   */
  (response) => {
    // console.log('point',process.env,process.env.VUE_APP_BASE_API,process.env.VUE_APP_BASE_API_OPINT)
    const res = response.data;

    // if the custom errcode is not 20000, it is judged as an error.
    if (
      res.errcode !== 0 &&
      res.errcode !== 6 &&
      res.errcode !== 503 &&
      res.errcode !== 504 &&
      res.errcode !== 506 &&
      res.errcode !== 505
    ) {
      Toast(res.errmsg);
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      // if (res.errcode === -1 || res.errcode === 50012 || res.errcode === 50014) {
      // to re-login
      // MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
      //   confirmButtonText: 'Re-Login',
      //   cancelButtonText: 'Cancel',
      //   type: 'warning'
      // }).then(() => {
      //   store.dispatch('user/resetToken').then(() => {
      //     location.reload()
      //   })
      // })
      // }
      if (res.errcode === 1) {
        // removeLocalStorage("accountStorage");
        // removeToken("login");
        // window.location.reload()
      }
      return Promise.reject(new Error(res.errmsg || "Error"));
    } else {
      return res;
    }
  },
  (error) => {
    console.log("err" + error); // for debug
    return Promise.reject(error);
  }
);

export default service;
