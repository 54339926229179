const configData = {
  // platform: "pc",
  // app_id: "408a32e0ee5420b91916ceb38a31d85a",
  // // app_id:'b56834eccbacb636c9ec1011dac9953f',
  // kid: "666666666",
  // lang: "zh-tw",
  // sdk_code: "1",
};
// https://passport-v2-pc.fantanggame.com/
export default configData;

export function configDataFun() {
  console.log(this.$route);
}
