import request from "../utils/request"
// /pay/pre_order_qr_code 预备下单
export function preorder(data) {
  return request({
    url: "/pay/pre_order_qr_code",
    method: "post",
    data,
  });
}

// /pay/pay_qr_code
export function payTrade(data) {
  return request({
    url: "/pay/order_qr_code",
    method: "post",
    data,
  });
}
// /pay/querycode 查询结果
export function querycode(data) {
  return request({
    url: "/pay/query_qr_code",
    method: "post",
    data,
  });
}
// /pay/refresh_qr_code 重新刷新二维码
export function refreshCode(data) {
  return request({
    url: "/pay/refresh_qr_code",
    method: "post",
    data,
  });
}