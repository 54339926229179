import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/icon/iconfont.css";
import Toast from "@/components/message/Toast";
import "swiper/css/swiper.min.css";
import "@/permission"; // permission control
import VueI18n from "vue-i18n";
import { getburiedPoint } from "@/utils/buriedPoint";
// import VConsole from "vconsole/dist/vconsole.min.js";
// let vConsole = new VConsole();
// import GSignInButton from "vue-google-signin-button";
import FBSignInButton from "vue-facebook-signin-button";
import tool from "@/utils/tool.js";
import VueFungoLogin from "vue-fungo-login";
import "./env";
Vue.use(VueFungoLogin);
// Vue.use(GSignInButton);
Vue.use(FBSignInButton);
Vue.use(tool);
Vue.prototype.$getburiedPoint = getburiedPoint;
Vue.prototype.$toast = Toast;
Vue.use(VueI18n);
let lang = "";
if (Vue.prototype.$isEn) {
  lang = "en";
  document.title = "catfantasy-payment";
} else if (Vue.prototype.$isTw) {
  lang = "tw";
  document.title = "貓之城-儲值中心";
} else {
  lang = "kr";
  document.title = "캣 판타지-상점";
}
const i18n = new VueI18n({
  // locale: localStorage.getItem("lang") || "zh", // 语言标识
  locale: lang,
  messages: {
    tw: require("./assets/lang/zh-tw"),
    en: require("./assets/lang/en-us"),
    kr: require("./assets/lang/ko-kr"),
  },
});
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
