import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home/index.vue";
import WebPay from "@/layout/webPay.vue";
import Index from "@/views/Home/index1.vue";
import Register from "@/views/AccountAdmin/index.vue";
import Pay from "@/views/Pay/index";
import Trade from "@/views/Pay/trade";
import Agreement from "@/views/Agreement/index";
import ChildTips from "@/views/ChildTips/index";
import MyCard from "@/views/MyCard/index";

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  //   {
  //     path: "/",
  //     name: "游戏初始化",
  //     component: () => import("@/views/Home/init.vue"),
  //   },
  {
    path: "/",
    redirect: "accountlogin",
    component: WebPay,
    // component: Home,
    children: [
      {
        path: "/accountlogin",
        name: "账号登录",
        component: () => import("@/views/Home/components/accountLogin"),
        meta: {
          keepAlive: true, // 需要被缓存
        },
      },
      {
        path: "/codelogin",
        name: "扫码登录",
        component: () => import("@/views/Home/components/codeLogin"),
      },
      {
        path: "/payOnline",
        name: "在线支付",
        component: () => import("@/views/MyCard/components/payOnline"),
        children: [
          {
            path: "/orderList",
            name: "我的订单",
            component: () => import("@/views/MyCard/components/orderList"),
          },
        ]
      },
      //   {
      //     path: "/bindEmail",
      //     name: "绑定邮箱",
      //     component: () => import("@/views/Home/components/bindPhone.vue"),
      //   },
      //   {
      //     path: "/checkuser",
      //     name: "用户邮箱验证",
      //     component: () => import("@/views/Home/components/checkuser.vue"),
      //   },
    ],
  },
  {
    path: "/orderPay",
    name: "在线支付",
    component: () => import("@/views/MyCard/components/orderPay"),
  },
  {
    path: "/bindemail",
    redirect: "accountlogin",
    component: Index,
    children: [
      {
        path: "/bindEmail",
        name: "绑定邮箱",
        component: () => import("@/views/Home/components/bindPhone.vue"),
      },
      {
        path: "/checkuser",
        name: "用户邮箱验证",
        component: () => import("@/views/Home/components/checkuser.vue"),
      },
    ],
  },
  {
    path: "/bindID",
    name: "绑定身份证",
    component: () => import("@/views/Home/components/bindID.vue"),
  },
  {
    path: "/account",
    component: Register,
    redirect: "/register",
    children: [
      {
        path: "/register",
        name: "注册",
        component: () => import("@/views/AccountAdmin/register.vue"),
      },
      {
        path: "/changePassword",
        name: "修改密码",
        component: () =>
          import("@/views/AccountAdmin/components/changePassword.vue"),
      },
      {
        path: "/findAccountGuide",
        name: "修改密码",
        component: () =>
          import("@/views/AccountAdmin/components/findAccountGuide.vue"),
      },
      {
        path: "/sendPhone",
        name: "发送验证码",
        component: () =>
          import("@/views/AccountAdmin/components/sendPhone.vue"),
      },
      //
    ],
  },
  {
    path: "/pay",
    component: Pay,
    redirect: "/pay/page",
    children: [
      {
        path: "/pay/page",
        name: "支付",
        component: () => import("@/views/Pay/components/codePay.vue"),
      },
      {
        path: "/pay/success",
        name: "支付成功",
        component: () => import("@/views/Pay/components/success.vue"),
      },
    ],
  },
  {
    path: "/tradeInfo",
    component: Trade,
    redirect: "/trade/page",
    children: [
      {
        path: "/trade/page",
        name: "支付详情",
        component: () => import("@/views/Pay/components/payInfo_h5.vue"),
      },
      {
        path: "/trade/success",
        name: "支付成功",
        component: () => import("@/views/Pay/components/tradesuccess.vue"),
      },
      {
        path: "/trade/error",
        name: "支付成功",
        component: () => import("@/views/Pay/components/traderror.vue"),
      },
    ],
  },
  {
    path: "/success",
    component: () => import("@/views/Home/success.vue"),
    name: "进入游戏",
  },
  {
    path: "/agreement",
    component: Agreement,
    redirect: "/userAgreement",
    children: [
      {
        path: "/userAgreement",
        name: "用户协议",
        component: () => import("@/views/Agreement/components/user.vue"),
      },
      {
        path: "/privacyAgreement",
        name: "隐私协议",
        component: () => import("@/views/Agreement/components/privacy.vue"),
      },
      {
        path: "/agreementInfo",
        name: "隐私协议",
        component: () => import("@/views/Agreement/components/privacy.vue"),
      },
    ],
  },
  {
    path: "/childprotect",
    component: ChildTips,
    redirect: "/childLogin",
    children: [
      {
        path: "/childLogin",
        name: "未成年人登录",
        component: () =>
          import("@/views/ChildTips/components/childLoginTips.vue"),
      },
      {
        path: "/childRegister",
        name: "隐私协议",
        component: () =>
          import("@/views/ChildTips/components/childRegister.vue"),
      },
    ],
  },
  {
    path: "/safetySettings",
    name: "隐私协议web",
    component: () => import("@/views/AccountAdmin/safetySettings.vue"),
  },
  {
    path: "/agreementPrivacy",
    name: "隐私协议web",
    component: () =>
      import("@/views/Agreement/components/agreementPrivacy.vue"),
  },
  {
    path: "/agreementUser",
    name: "用户协议web",
    component: () => import("@/views/Agreement/components/agreementUser.vue"),
  },
  {
    path: "/linkgame",
    name: "通知游戏",
    component: () => import("@/views/Home/linkgame.vue"),
  },
  {
    path: "/childTips",
    name: "未成年人实名提示",
    component: () => import("@/views/AccountAdmin/components/bannerGame.vue"),
  },
  {
    path: "/mycard",
    name: "mycard",
    component: MyCard,
    children: [
      {
        path: "/mycard/paygift",
        name: "myCard网页支付",
        component: () => import("@/views/MyCard/components/payGift"),
        meta: {
          keepAlive: true, // 需要被缓存
        },
      },
      {
        path: "/mycard/final",
        name: "支付结果",
        component: () => import("@/views/MyCard/components/final"),
        meta: {
          keepAlive: true, // 需要被缓存
        },
      },
      {
        path: "/gash/final",
        name: "支付结果",
        component: () => import("@/views/MyCard/components/final"),
        meta: {
          keepAlive: true, // 需要被缓存
        },
      },
      {
        path: "/loading",
        name: "loading",
        component: () => import("@/views/MyCard/components/loading"),
        meta: {
          keepAlive: true, // 需要被缓存
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
