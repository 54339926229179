module.exports = {
  toast: {
    agreementTips: "請先閱讀並同意協議",
    nopsw: "密碼不能為空",
    verifypAccount: "帳號格式不正確",
    noAccount: "帳號不能為空",
    gotoGame: "登入",
    noPhone: "信箱不能為空",
    verifyphoneTips: "請輸入正確信箱",
    verifycodeTips: "驗證碼不能為空",
    verifypswTips: "密碼需為6-18位元非中文/空格字元",
    newverifypswTips: "請輸入6-18位元非中文/空格字元的新密碼",

    noName: "姓名不能為空",
    noID: "",
    verifyIDTips: "",
    confirmPswTips: "請保持兩次輸入密碼一致",

    accountPlaceholder: "請輸入信箱",
    phonePlaceholder: "請輸入信箱",
    pswPlaceholder: "請輸入密碼",
    newPswPlaceHolder: "請輸入新密碼",
    confirmPswPlaceholder: "請確認密碼",
    IDPlaceholder: "",
    namePlaceholder: "",
    verifycodePlaceholder: "請輸入驗證碼",
    findAccountPlaceHolder: "請輸入您要找回的帳號",

    findAccountTxt1: "請輸入需要重置密碼的帳號",
    findAccountTxt2: "點擊獲取驗證碼,簡訊將發送至您的綁定",
    verifycodeBtn: "獲取",
    verifycodeBtn2: "重新獲取",
    gotoRegister: "立即註冊",
    findAccount: "忘記密碼",
    gotoAccountLogin: "帳號密碼登入",
    agreementText: "我已閱讀並同意",
    userAgreementName: "使用者協議",
    privacyAgreementName: "隱私權協議",
    agreementText2: "註冊即代表您同意並遵守",
    loginErrorTips: "登入狀態異常需要完成安全驗證",
    phoneVerify: "驗證綁定信箱",
    and: "和",
    comfirmBtn1: "提交",
    comfirmBtn2: "確認",
    comfirmBtn3: "註冊",
    comfirmBtn4: "返回",
    comfirmBtn5: "取消",
    comunicaton1: "信箱",
    comunicaton2: "信箱",
    changepswTxt1: "設置帳號",
    changepswTxt2: "的新密碼",

    bannerGameTxt1: "提示",
    bannerGameTxt2: "",
    bannerGameTxt3: "",

    agreementTips1: "使用者協議內容有更新，請認真閱讀。點擊同意後才可以使用本產品",
    agreementTips2: "閱讀並同意全部協議後可繼續遊戲",
    agreementTips3: "拒絕",
    agreementTips4: "同意",
    agreementTips5: "閱讀完全部內容，可點擊同意按鈕",
    agreementTips6: "請閱讀並同意協議，再進登入",

    bindIdTxt1: "根据国家要求规定，游戏用户必须进行实名认证，",
    bindIdTxt2: "我们承诺将严格保密您的信息安全。",

    bindPhoneTxt1: "請輸入需要綁定的信箱：",

    loginErrorTxt1: "登入狀態異常需要完成安全驗證",
    loginErrorTxt2: "驗證綁定信箱：",

    successTitle: "歡迎進入遊戲",
  },
  myCard: {
    titleAccount: "帳號資訊",
    account: "帳號",
    activeAccount: "當前帳號",
    password: "密码",
    titlePayWay: "儲值管道",
    titlePayAccount: "儲值金額",
    titlePayList: "貓眼石售價表",
    myCardPoint: "MyCard點數",
    myCardGiftNum: "貓眼石（個）",
    submit: "提 交",
    change: "進行儲值兌換",
    logout: "登出",
    gameRole: "角色",
    noGameRole: "暫無綁定遊戲角色,請切換帳號重試",
    point: '點',
    cardNum: '卡號',
    cardPass: '卡密',
    placeholderCardNum: '請輸入卡號',
    placeholderCardPass: '請輸入密碼',
    extraGift: '額外贈送',
    time: '倒數計時',
    timeout: '已截止',
    payWay1: '錢包扣點',
    payWay2: '點數卡',
    payWay3: '會員扣點',
    payWay4: '卡片儲值',
    secTitle1: '活動專屬',
    secTitle2: '超值回饋',
    secTitle3: '獨家優惠',
    secTitle4: '常駐商店',
    failed: '操作失敗',
    tip: '官網儲值與遊戲內儲值貓眼石檔位共享雙倍福利',
    days: '天',
    pay1: '請盡快完成付款',
    pay2: '已完成付款',
    pay3: '未查詢到結果',
    pay4: '若已完成付款，稍後請在遊戲內領取',
    pay5: '稍後請在遊戲內領取',
    loginFirst: '請先登入遊戲帳號',
    extraSend: '額外贈：',
    extraTitle: '自訂貓眼石數量',
    stock: '庫存：',
    otherNum: '其他數量',
    chooseCoupon: '選擇優惠券',
    loginTips: '完成登入後可查看',
    noCoupon: '暫無可用優惠券',
    expireAt: '有效期至：',
    disCount: 'off',
    useType: '滿{amount}可用',
    proInfo: '商品資訊：',
    proNum: '商品數量：',
    roleInfo: '角色資訊：',
    proPrice: '商品原價：',
    disCountPrice: '折扣金額：',
    totalPrice: '合計：',
    inputNum: '輸入儲值數量',
    extraTips: '說明：輸入儲值數量必須大於6480，且為10的倍數，單次最多不超過99990',
    chooseRole: '角色選擇',
    maxNum: '最高抵扣',
    couponTips: '您有一筆正在付款的訂單中使用了優惠券，請問是否要取消訂單退還優惠券？',
    couponTips2: '',
    useCoupon: '使用的優惠券：',
    originalAmount: '商品原價：',
    productSubject: '商品資訊：',
    payAmount: '付款金額：',
    couponBtn1: '取消並退還優惠券',
    couponBtn2: '繼續付款上筆訂單',
    closeMyCard: '關閉當前頁面',
    empty: '若要查看商品，請先登入遊戲帳號~',
    coupon: '優惠券',
    check: '查看',
    orderTitle: '確認付款訂單',
    checkCoupon: '查看優惠券',
    orderCancel: '訂單已取消',
    orderListTitle: '網頁付款記錄查詢',
    orderListSecTitle: '最多支持查詢一年內記錄',
    orderListEmpty: '暫無購買記錄',
    orderListPayWay: '付款',
    orderListPayTime: '付款時間:',
    orderListCreateTime: '下單時間:',
    orderListNum: '訂單號:',
    orderListExtra: '包含VAT',
    orderListCloseTime: '訂單關閉倒數計時:',
    orderListClose: '取消付款',
    orderListPay: '前往付款',
    loadMore: '查看更多',
    orderTab1: '全部',
    orderTab2: '未付款',
    orderTab3: '已付款',
    orderTab4: '已取消',
    orderTab5: '訂單關閉',
    navHome: '首頁',
    navMyOrder: '我的訂單',
    exchangeCoupon: '兌換優惠券',
    exchangeTip: '請輸入兌換碼',
    exchangeCode: '兌換碼:',
    exchangeCodeTip: '請輸入兌換碼(16位大寫字母+數字)',
    exchange: '兌換',
    hasCoupon: '恭喜你獲得優惠券！ ',
    checkExchangeCoupon: '查看/兌換優惠券',
    share: '可疊加'
  },
  payRule: {
    title: '儲值說明',
    text1: '1. 購買時請務必再次確認您所輸入的交易諮詢， 商品交易成功後， 將無法轉移至其他帳號。',
    text2: '2. 購買完成後，商品將依照當日連線狀況入帳，需要時間最少5分鐘、至多40分鐘。如有限時優惠活動，請於活動結束時間前一小時完成購買程式，以免物品進帳時計算到隔日天數。',
    text3: '3. 有任何疑問，請來信客服詢問，客服郵箱：catcs.contact@gmail.com，處理時間約3-5工作天。',
    text4: '',
    text5: '',
    text6: '',
  },
  final: {
    success: '交易成功',
    failed: '交易失敗',
    goBack: '返回首頁',
    tips: '獎勵已發放，請至遊戲內的郵件領取'
  },
  rule: {
    title: '注意事項',
    text1: '1. 購買時請務必再次確認您所輸入的交易諮詢， 商品交易成功後,將無法轉移至其他帳號。',
    text2: '2. 購買完成後，商品將依照當日連線狀況入帳，需要時間最少5分鐘、至多40分鐘。如有限時優惠活動，請於活動結束時間前一小時完成購買程式，以免物品進帳時計算到隔日天數。',
    text3: '3. 有任何疑問，請來信客服詢問，客服信箱：catcs.contact@gmail.com，處理時間約3-5工作天。',
  }
};
